import AvodLogo from '../../assets/images/avod.png';
import { AvodImageLogo, FooterContainer, PoweredText } from './style';

const Footer = () => {
  return (
    <FooterContainer>
      <PoweredText>Powered by</PoweredText>
      <AvodImageLogo src={AvodLogo} />
    </FooterContainer>
  )
};

export default Footer;