import styled from 'styled-components';

interface ContainerProps {
  $image: any;
}

export const Container = styled.div<ContainerProps>`
  background-repeat: no-repeat;
  background-image: url(${props => props.$image});
  background-size: cover;
  text-align: center;
  position: relative;
  color: #e6e6e6;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    background-position: 25%;
  }
`;