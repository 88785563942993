import styled from "styled-components";

export const StyledButton = styled.button`
  padding: 16px 58px;
  border-radius: 8px;
  background: #F66F40;
  text-align: center;
  width: 100%;
  border: none;
  outline: none;
  user-select: none;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  color: white;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-family: "Noto Sans";

  &:disabled {
    background-color: #F66F40;
    opacity: 0.5;
  }
`;