import React, { useEffect, useState } from 'react';
import { CiClock1 } from "react-icons/ci";
import { TimerContainer } from './style';

interface TimerProps {
  initialDate: Date;
}

const Timer: React.FC<TimerProps> = ({ initialDate }) => {
  const [elapsedTime, setElapsedTime] = useState<string>('');

  const calculateElapsedTime = () => {
    const now = new Date();
    const elapsedMilliseconds = now.getTime() - initialDate.getTime();

    const hours = Math.floor(elapsedMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor((elapsedMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((elapsedMilliseconds % (1000 * 60)) / 1000);

    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    const formattedTime = `${hours > 0 ? hours + 'h ' : ''}${formattedMinutes}m ${formattedSeconds}s`;
    setElapsedTime(formattedTime);
  };

  useEffect(() => {
    calculateElapsedTime();

    const interval = setInterval(calculateElapsedTime, 1000);

    return () => clearInterval(interval);
  }, [initialDate]);

  return (
    <TimerContainer>
      <CiClock1 size={14} />
      {elapsedTime}
    </TimerContainer>
  );
};

export default Timer;