import styled from "styled-components";

export const TimerContainer = styled.div`
  margin: 0px 4px;
  white-space: nowrap;
  font-size: 12px;
  color: white;
  display: flex;
  align-items: center;
  gap: 2px;
`;