import { Routes, Route } from 'react-router-dom';

import Home from './pages/home/index';

const AppRoutes = () => {
  return (
    <Routes>
      <Route index path="/" element={<Home />} />
    </Routes>
  )
}

export default AppRoutes;