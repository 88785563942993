import HitsMidLogo from "../HitsMidLogo";

import { BoxContainer, Container, GoBackIcon, Title, TitleContainer } from "./style";

interface StepBoxProps {
  children: any;
  onBackButtonClicked?: () => void;
  stepTitle: string;
}

const StepBox = ({ stepTitle, onBackButtonClicked, children }: StepBoxProps) => {
  return (
    <Container>
      <HitsMidLogo marginLeft="22px"  />
      <BoxContainer>
        <TitleContainer>
          {onBackButtonClicked && <GoBackIcon onClick={onBackButtonClicked} />}
          <Title>{stepTitle}</Title>
        </TitleContainer>
        {children}
      </BoxContainer>
    </Container>
  )
}

export default StepBox;