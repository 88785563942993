let isMeetingRoomBackgroundVideoSet = false;
let isVirtualBackgroundAdjusted = false;
let meetingHeaderInserted = false;
let isTimerInserted = false;

let isMeetingHeaderUpdated = false;

function updateZoomMeetingHeader(element: Element) {
  // hitsmid logo
  const headerHitsmidLogo = document.createElement("img");
  headerHitsmidLogo.className = "meeting-header-hitsmid-logo";

  // avodtech logo
  const headerAvodLogoContainer = document.createElement("div");
  headerAvodLogoContainer.className = "meeting-header-avod-container";

  const headerAvodText = document.createElement("span");
  headerAvodText.className = "meeting-header-avod-text";
  headerAvodText.textContent = "Powered by"

  const headerAvodLogo = document.createElement("img");
  headerAvodLogo.className = "meeting-header-avod-logo";

  headerAvodLogoContainer.appendChild(headerAvodText);
  headerAvodLogoContainer.appendChild(headerAvodLogo);

  // header logos container
  const headerLogosContainer = document.createElement("div");
  headerLogosContainer.className = "meeting-header-logo-container";

  headerLogosContainer.appendChild(headerHitsmidLogo);
  headerLogosContainer.appendChild(headerAvodLogoContainer); 

  element.insertBefore(headerLogosContainer, element.firstChild);
}

function insertMeetingTimer() {
  const timerElement = document.querySelector('.hitsmid-meeting-timer') as HTMLElement;
  const meetingHeaderLogoContainer = document.querySelector('.meeting-header-logo-container') as HTMLElement;

  if (timerElement && meetingHeaderLogoContainer) {
    timerElement.style.display = "block";    
    meetingHeaderLogoContainer.insertAdjacentElement('afterend', timerElement)
  }
}

function callback() {
  const waitingPanel = document.getElementsByClassName('waiting-room-container');
  const waitingPanelMobile = document.getElementsByClassName('mobile-waiting-room');

  const maskArea = document.querySelector(".mask-area");
  const highlightParticipantVideo = document.querySelector(".speaker-active-container__video-frame");
  
  const wcContent = document.querySelector("#wc-content"); // Reunião iniciada
  
  const meetingHeader = document.querySelector('.meeting-header.zoom-meetingsdk-meeting-header');
  const meetingFooterAiCompanion = document.querySelector(".footer-AIC-button");
  const meetingHeaderLogos = document.querySelector('.meeting-header-logo-container');

  const timerElement = document.querySelector('.hitsmid-meeting-timer');

  const waitingRoomTopic = document.querySelector('.wr-topic');

  if (!isMeetingHeaderUpdated && meetingHeader && !meetingHeaderLogos) {
    updateZoomMeetingHeader(meetingHeader);
    isMeetingHeaderUpdated = true;
  }

  if (!isTimerInserted && meetingHeaderLogos && timerElement?.hasChildNodes()) {
    insertMeetingTimer()
    isTimerInserted = true;
  }
  
  if (meetingFooterAiCompanion) {
    meetingFooterAiCompanion.remove();
  }

  if (waitingRoomTopic) {
    waitingRoomTopic.remove();
  }

  if (!isMeetingRoomBackgroundVideoSet && (waitingPanel?.length || waitingPanelMobile?.length)) {
    console.info('== Entrou na sala de espera ==');
    isMeetingRoomBackgroundVideoSet = true;

    const video = document.createElement("video");
    const wrMainContent = document.querySelector(".wr-main-content");
    if (wrMainContent) {
      // content.style.backgroundColor = "#FFF";
      // content.style.width = "100%";  
    
      video.style.position = "absolute";
      video.style.zIndex = '0';
      video.style.inset = '0';
      video.style.minWidth = "100%";
      video.style.minHeight = "100%";
      video.style.width = "100%";
      video.style.height = "100%";
      video.autoplay = true;
      video.loop = true;
      video.src = "https://dl.dropboxusercontent.com/s/dkmh4lsj4rtj4go8l708v/hitsmid-logo.mp4?rlkey=z7aa28wl0chejc6z26k9uugyr&dl=0";
      video.crossOrigin = "anonymous";
      
      wrMainContent.replaceChildren();
      wrMainContent.appendChild(video);
    };

    const websdkPassword = document.getElementById("websdk-password");
    if (websdkPassword) {
      websdkPassword.style.backgroundColor = "#FFF";
      websdkPassword.style.zIndex = '1';
      websdkPassword.style.position = "absolute";
      websdkPassword.style.bottom = '0';
    };

    const mQuery = window.matchMedia('(max-width: 850px)')
    if (mQuery.matches) {
      const headerAvodLogoContainer = document.getElementsByClassName('meeting-header-avod-container');
      if (headerAvodLogoContainer) {
        const element = headerAvodLogoContainer[0] as HTMLElement;
        element.style.display = "none";
      }
      const waitingPannelTitle = document.getElementsByClassName("waiting-pannel-title");
      if (waitingPannelTitle && waitingPannelTitle.length) {
        const element = waitingPannelTitle[0] as HTMLElement;
        element.style.margin = '0';
        element.style.padding = '0';
      };

      const waitingPannelBtn = document.getElementsByClassName("waiting-pannel-btn");
      if (waitingPannelBtn && waitingPannelBtn.length) {
        const element = waitingPannelBtn[0] as HTMLElement;
        element.style.margin = '0';
      };
      
      const formGroup = document.getElementsByClassName("form-group");
      if (formGroup && formGroup.length) {
        const element = formGroup[0] as HTMLElement;
        element.style.margin = "0";
      };

      video.style.objectFit = "contain";
    } else {
      if (websdkPassword) {
        websdkPassword.style.padding = "0 3% 0 3%";
        websdkPassword.style.right = '0';
      };
  
      video.style.objectFit = "cover";
    }
  }

  if (highlightParticipantVideo) {
    const width = highlightParticipantVideo.clientWidth * 0.96;
    const meetingLeftSide = document.querySelector(".meeting-info-container--left-side");
    if (meetingLeftSide) {
      (meetingLeftSide as HTMLDivElement).style.width = `${width}px`;
    }
  }
}

const observer = new MutationObserver(callback);

export function initializeObserver() {
  const targetNode = document.getElementById("zmmtg-root");
  if (targetNode) {
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
    console.info('Observer started');
  }
};

export function stopObserver() {
  observer.disconnect();
  console.log('Observer stopped');
}