import { API } from "./baseService/baseService";

function requestAccessToken(code: string) {
  try {
    return API.post("/auth/token", { code });
  } catch (error) {
    throw error;
  }
};

function refreshAccessToken(refresh_token: string) {
  try {
    return API.post("/auth/token/refresh", { refresh_token });
  } catch (error) {
    throw error;
  }
}

function getSignature(data: SignatureBody) {
  try {
    return API.post("/auth/signature", data)
  } catch (error) {
    throw error;
  }
}

const AuthService = {
  requestAccessToken,
  refreshAccessToken,
  getSignature
}

export default AuthService;