import { useEffect, useRef, useState } from "react";
import TextField from "../TextField";
import StepBox from "../StepBox";
import { ZoomMtg } from '@zoom/meetingsdk';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from "../../context";
import Button from "../Button";
import { vbList } from "../../constants/virtualBackground";
import AjxService from "../../services/ajx";
import Timer from "../Timer";
import { createRoot } from "react-dom/client";
import MeetingServices from "../../services/meeting";

const JoinMeeting = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, switchToInitialPageStep, joinMeeting } = useAppContext();

  const [joinMeetingParams, setJoinMeetingParams] = useState<JoinMeetingParams>({
    email: "",
    username: "",
    roomCode: "",
    password: ""
  });

  // Flag se o participante é um coHost ou usuario normal
  const isCoHost = useRef<boolean>(false);
  // Lista de participantes pinados pelo userId
  const pinnedBrokers = useRef<number[]>([]);
  // Usado para diferenciar os varios possiveis usuarios que estão sendo 
  // atualizados. Dicionario que usa o userId como key 
  const onUserUpdateHandler = useRef<OnUserUpdateHandler>();
  // Para o timeout de onUserJoin
  const onJoinUserTimeoutId = useRef<number | null>();
  // Lista de todos os liderados do lider
  const leaderBrokersEmail = useRef<string[]>([]);

  const handleJoinMeetingForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let value = event.target.value;

    if (name === "roomCode") {
      value = event.target.value.replace(/\s/g, '');
      if (value.length > 12) return;
    };

    setJoinMeetingParams(currState => ({ ...currState, [name]: value }))
  };

  const handleJoinSuccess = async (success: ZoomSDKResponse) => {
    console.log('== JOIN SUCCESS: ', success);

    if (!searchParams.has("room")) {
      searchParams.append("room", joinMeetingParams.roomCode);
      searchParams.append("password", joinMeetingParams.password);
      setSearchParams(searchParams);
    }

    const response = await MeetingServices.getMeeting(joinMeetingParams.roomCode);
    if (response.status === 200) {
      const data: Meeting = response.data;

      const meetingElement = document.getElementById('zmmtg-root');
      if (meetingElement) {
        const element = document.createElement('div');
        element.className = "hitsmid-meeting-timer";
        element.style.display = "none";
        meetingElement.appendChild(element);
        const root = createRoot(element)
        root.render(<Timer initialDate={new Date(data.created_at)} />)
      }
    }

    // ZoomMtg.updateVirtualBackgroundList({
    //   vbList: vbList,
    //   success: updateVirtualBackgroundListSuccess,
    //   error: updateVirtualBackgroundListError,
    // });

    // ZoomMtg.getCurrentUser({
    //   success: getCurrentUserSuccess,
    //   error: getCurrentUserError
    // });

    // ZoomMtg.inMeetingServiceListener("onUserUpdate", handleOnUserUpdate);
    // ZoomMtg.inMeetingServiceListener("onUserLeave", handleOnUserLeave);
  };

  // == updateVirtualBackgroundList
  const updateVirtualBackgroundListSuccess = (event: any) => {
    console.log('Successfully updated the virtual background list. ', event);
  };

  const updateVirtualBackgroundListError = (event: any) => {
    console.error('An error occurred while updating the virtual background list. ', event);
  };

  // == getCurrentUser
  const getCurrentUserSuccess = async (user: GetCurrentUser) => {
    console.log('getCurrentUserSuccess');

    try {
      const data = {
        attendee_id: user.result.currentUser.userId + "",
        session_id: joinMeetingParams.roomCode + "",
        email_attendee: joinMeetingParams.email
      }
      console.log('data: ', data);

      await AjxService.addAttendanceHistory(data);
    } catch (err) {
      console.error("[getCurrentUserSuccess]: ", err);
    }
  };

  const getCurrentUserError = (error: any) => {
    console.error('[getCurrentUser]: ', error);
  };

  // == onUserUpdate
  const handleOnUserUpdate = (event: InMeetingServiceListenerCallback) => {
    // Timeout é utilizado já que o Zoom realiza a execução do userUpdate varias vezes, 
    // então é utilizado um delay de 2s para obter o ultimo userUpdate event. Para os 
    // demais é realizado o reset do timeout 
    if (onUserUpdateHandler && onUserUpdateHandler.current && onUserUpdateHandler.current[event.userId]) {
      clearTimeout(onUserUpdateHandler.current[event.userId]);
    }

    const timeoutId = window.setTimeout(() => {
      handleOnUserUpdateAction(event);
    }, 1000);

    onUserUpdateHandler.current = {
      ...onUserUpdateHandler.current,
      [event.userId]: timeoutId
    }
  };

  const handleOnUserUpdateAction = (user: InMeetingServiceListenerCallback) => {
    // console.log('handleOnUserUpdateAction: ', user);
    
    // Por esse caminho só ira fazer o pin 1x, quando for detectado que o 
    // participante foi tornado co-host. Após somente pelo onUserJoin. É usado
    // para fazer o pin dos participantes que já estão na chamada
    if (user.isCoHost && user.userName === joinMeetingParams.username && !isCoHost.current) {
      isCoHost.current = true;
      getLeaderBrokers();
    }
  };

  const getLeaderBrokers = async () => {
    try {
      const brokersResponse = await AjxService.brokersByLeaderEmail(joinMeetingParams.email);
      if (brokersResponse.status === 200) {
        const data = brokersResponse.data;
        const brokers = data?.data?.result?.brokers;
        console.log('brokers: ', brokers);
        if (brokers && Array.isArray(brokers) && brokers.length) {
          leaderBrokersEmail.current = brokers.map(broker => broker.email).filter(broker => broker !== joinMeetingParams.email);
          // Inicializa listener de novos usuarios na chamada = ETAPA 2
          ZoomMtg.inMeetingServiceListener("onUserJoin", handleOnUserJoin);
          getParticipantsAndPinBrokers();
        }
      }
    } catch (err) {
      console.error('[getLeaderBrokers]: ', err);
    }
  };

  const getParticipantsAndPinBrokers = async () => {
    console.log('getParticipantsAndPinBrokers');

    try {
      onJoinUserTimeoutId.current = null;
      // Obtem a lista de todas as pessoas que estão na reunião (para obter o userId delas)
      const meetingParticipantsResponse = await AjxService.leadersAttendanceBySessionId(joinMeetingParams.roomCode + "", "N");
      if (meetingParticipantsResponse.status === 200) {
        const data = meetingParticipantsResponse.data;
        const participants = data.data;
        const brokers = leaderBrokersEmail.current;
        if (participants && Array.isArray(participants) && participants.length) {
          // É realizado uma filtragem de todos os participantes com: 
          //  - somente os brokers do lider, excluindo o proprio lider
          //  - brokers que não foram ainda pinados
          const leaderBrokersInCall = participants.filter(participant => {
            return brokers.find(brokerEmail => brokerEmail === participant.email && joinMeetingParams.email !== brokerEmail && !pinnedBrokers.current.includes(participant.attendee_id))
          })
          console.log('leaderBrokersInCall: ', leaderBrokersInCall);
          for (const broker of leaderBrokersInCall) {
            if (pinnedBrokers.current.length >= 9) break;

            const userId = Number.parseInt(broker.attendee_id, 10); 
            ZoomMtg.operatePin({
              operate: "add",
              userId: userId,
              success: (e: any) => {
                console.log('operatePin success: ', e);
                pinnedBrokers.current = [...pinnedBrokers.current, userId];
              },
              error: (e: any) => console.error('operatePin error: ', e)
            })
          }
        }
      }
    } catch (err) {
      console.error("[getParticipantsAndPinBrokers]: ", err);
    }
  };

  // == onUserJoin
  const handleOnUserJoin = () => {
    // Verifica se:
    //  - participante é co-host
    //  - se ainda é possivel pinar usuarios (até 9)
    //  - se todos os brokers já foram pinados
    // Aguarda 3s para ver se mais pessoas entram
    if (isCoHost.current && pinnedBrokers.current.length < 9 && pinnedBrokers.current.length !== leaderBrokersEmail.current.length) {
      if (!onJoinUserTimeoutId.current) {
        onJoinUserTimeoutId.current = window.setTimeout(getParticipantsAndPinBrokers, 3000);
      } 
    }
  };

  // == onUserLeave
  const handleOnUserLeave = (user: User) => {
    console.log('handleOnUserLeave: ', user);

    if (isCoHost.current) {
      pinnedBrokers.current = pinnedBrokers.current.filter(i => i !== user.userId);
    }
  };

  // == joinMeeting
  const joinMeetingAsParticipant = () => {
    joinMeeting(
      joinMeetingParams.username,
      joinMeetingParams.password,
      joinMeetingParams.roomCode,
      0,
      handleJoinSuccess
    )
  };

  useEffect(() => {
    if (searchParams?.size && searchParams.has("room")) {
      const newRoomCode = searchParams.get("room") ?? "";
      const newPassword = searchParams.get("password") ?? "";
  
      if (
        newRoomCode !== joinMeetingParams.roomCode ||
        newPassword !== joinMeetingParams.password
      ) {
        setJoinMeetingParams({ 
          ...joinMeetingParams, 
          roomCode: newRoomCode,
          password: newPassword
        });
      }
    }
  }, [searchParams, setJoinMeetingParams]);

  const backAndClearParameters = () => {
    setSearchParams({})
    switchToInitialPageStep()
  }

  return (
    <StepBox stepTitle="Entrar em uma reunião" onBackButtonClicked={() => {backAndClearParameters()}}>
      <TextField
        isRequired
        type="email"
        placeholder="Insira seu Email"
        name="email"
        onChange={handleJoinMeetingForm}
        value={joinMeetingParams.email}
        label="Email"
      />
      <TextField
        isRequired
        type="text"
        placeholder="Nome a ser usado durante a reunião"
        name="username"
        onChange={handleJoinMeetingForm}
        value={joinMeetingParams.username}
        label="Nome do Usuário"
      />
      <TextField
        isRequired
        type="number"
        placeholder="Código de acesso da reunião"
        name="roomCode"
        onChange={handleJoinMeetingForm}
        value={joinMeetingParams.roomCode}
        label="Código de Acesso"
      />
      <TextField
        isRequired
        type="password"
        allowPasswordVisualization
        placeholder="Senha de acesso da reunião"
        name="password"
        onChange={handleJoinMeetingForm}
        value={joinMeetingParams.password}
        label="Senha"
        maxLength={10} // Zoom não permite criar senhas com mais de 10 caracteres
      />
      <Button
        text="Entrar"
        isLoading={isLoading}
        onClick={joinMeetingAsParticipant}
        disabled={
          isLoading ||
          !joinMeetingParams.roomCode ||
          !joinMeetingParams.password ||
          !joinMeetingParams.username.trim()
        }
      />
    </StepBox>
  );
}

export default JoinMeeting;