import styled from "styled-components";
import { RiVideoAddLine } from "react-icons/ri";
import { FiLogIn } from "react-icons/fi";

export const WelcomeWrapper = styled.div`
  display: flex;
  align-items: center;
  user-select: none;
  justify-content: space-between;
  width: 100%;
  margin: 0 8%;

  @media (max-width: 768px) {
    margin: 46px 35px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const WelcomeContent = styled.div`
  text-align: left;
`;

export const InitialPageText = styled.h3`
  color: #FFF;
  font-family: "Noto Sans";
  font-size: 72px;
  font-style: normal;
  font-weight: 700;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 48px;
    margin-top: 72px;
  }
`;

export const MeetingCreation = styled.div`
  display: flex;
  gap: 32px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    margin: 48px 0;
  }
`;

export const MeetingCreationButton = styled.div`
  font-weight: 700;
  border-radius: 16px;
  background: #F66F40;
  height: 150px;
  padding: 16px 32px;
  display: flex;
  align-items: flex-start;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  font-family: "Noto Sans";
  font-size: 14px;
`;

export const CreateMeetingIcon = styled(RiVideoAddLine)`
  font-size: 25px;
  color: black;
`;

export const JoinMeetingIcon = styled(FiLogIn)`
  font-size: 25px;
  color: black;
`;