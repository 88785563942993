import React, { Dispatch, useState } from 'react';
import AuthService from '../services/auth';
import { ZoomMtg } from '@zoom/meetingsdk';
import { CLIENT_ID } from '../constants';

enum StepsEnum {
  INITIAL_PAGE = "INITIAL_PAGE",
  JOIN_MEETING = "JOIN_MEETING",
  CREATE_MEETING = "CREATE_MEETING"
};

type AppContext = {
  isLoading: boolean;
  setIsLoading: Dispatch<boolean>;
  activeStep: StepsEnum;
  isHost: boolean;
  joinMeeting: (username: string, password: string, roomCode: string, isHost: 1 | 0, joinSuccess: (success: ZoomSDKResponse) => void) => void;
  switchToInitialPageStep: () => void;
  switchToJoinMeetingStep: () => void;
  switchToCreateMeetingStep: () => void;
}

const ApplicationContext = React.createContext({} as AppContext);

const AppProvider = ({ children }: { children: any }) => {
  const [isHost, setIsHost] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<StepsEnum>(StepsEnum.INITIAL_PAGE);

  const switchToInitialPageStep = () => setActiveStep(StepsEnum.INITIAL_PAGE);
  const switchToJoinMeetingStep = () => setActiveStep(StepsEnum.JOIN_MEETING);
  const switchToCreateMeetingStep = () => setActiveStep(StepsEnum.CREATE_MEETING);

  const joinMeeting = async (
    username: string,
    password: string,
    roomCode: string,
    isHost: 0 | 1,
    zoomJoinSuccess: (success: ZoomSDKResponse) => void
  ) => {
    setIsLoading(true);

    const data: SignatureBody = {
      meetingNumber: roomCode,
      role: isHost
    };

    try {
      const response = await AuthService.getSignature(data);
      if (response.status === 200) {
        const signature = response.data.signature;
        const meetingElement = document.getElementById('zmmtg-root');

        if (meetingElement) meetingElement.style.display = "block";

        ZoomMtg.init({
          leaveUrl: window.location.href,
          success: (success: ZoomSDKResponse) => {
            console.log('== INIT SUCCESS: ', success);
            setIsHost(!!isHost);

            ZoomMtg.join({
              signature: signature,
              sdkKey: CLIENT_ID,
              meetingNumber: roomCode,
              passWord: password,
              userName: username.replace(/\s+/g, ' ').trim(),
              success: (success: ZoomSDKResponse) => zoomJoinSuccess(success),
              error: (error: ZoomSDKResponse) => console.error('== JOIN ERROR: ', error)
            });
          },
          error: (error: ZoomSDKResponse) => console.error('== INIT ERROR: ', error)
        });
      }
    } catch (err) {
      console.error("[joinMeeting]: ", err);
    } finally {
      setIsLoading(false);
    };
  };

  return (
    <ApplicationContext.Provider
      value={{
        isHost,
        isLoading,
        setIsLoading,
        activeStep,
        joinMeeting,
        switchToInitialPageStep,
        switchToJoinMeetingStep,
        switchToCreateMeetingStep
      }}
    >
      {children}
    </ApplicationContext.Provider>
  )
}

export default AppProvider;

export function useAppContext() {
  const Context = React.useContext(ApplicationContext);
  return { ...Context };
}
