import styled from "styled-components";
import { MdArrowBackIosNew } from "react-icons/md";

export const Container = styled.div`
  margin-top: 32px;
  margin-bottom: 128px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 32px 8px 128px 8px;
    width: 100%;
  }
`;

export const BoxContainer = styled.div`
  background: #000;
  border-radius: 8px;
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  margin-top: 64px;
  width: 500px;
  max-width: 500px;

  @media (max-width: 768px) {
    margin-top: 96px;
    padding: 26px 22px;
    width: auto;
    max-width: none;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
  user-select: none;
`;

export const GoBackIcon = styled(MdArrowBackIosNew)`
  color: #F66F40;
  font-size: 20px;
  cursor: pointer;
`

export const Title = styled.span`
  color: #FFF;
  font-family: "Noto Sans";
  font-size: 20px;
  font-weight: 700;
`;