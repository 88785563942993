import styled, { keyframes } from "styled-components";
import { AiOutlineLoading3Quarters as LoadingIcon } from "react-icons/ai";

export const LoadingContainer = styled.div``;

const spin = keyframes`
  0% { 
    transform: rotate(0deg); 
  },
  100% {  
    transform: rotate(359deg); 
  }
`;

export const LoadingSpinner = styled(LoadingIcon)`
  animation: ${spin} 1s linear infinite;
`;