import BackgroundImage from '../../assets/images/d8f15d1af641b42418cc66970c103e5e.png';

import { ZoomMtg } from '@zoom/meetingsdk';
import { Container } from "./style";
import InitialPage from '../../components/InitialPage';
import { useAppContext } from '../../context';
import JoinMeeting from '../../components/JoinMeeting';
import CreateMeeting from '../../components/CreateMeeting';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { initializeObserver, stopObserver } from '../../utils/observer';
import Footer from '../../components/Footer';

enum StepsEnum {
  INITIAL_PAGE = "INITIAL_PAGE",
  JOIN_MEETING = "JOIN_MEETING",
  CREATE_MEETING = "CREATE_MEETING"
};

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('pt-PT');

const Home: React.FC = () => {
  const { activeStep, switchToJoinMeetingStep } = useAppContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams?.size && (searchParams.has("room") || searchParams.has("password"))) {
      switchToJoinMeetingStep();
    }
  }, [searchParams, switchToJoinMeetingStep]);

  useEffect(() => {
    initializeObserver();

    return () => {
      stopObserver()
    };
  }, []);

  return (
    <Container $image={BackgroundImage}>
      {!activeStep && <InitialPage />}
      {activeStep === StepsEnum.INITIAL_PAGE && <InitialPage />}
      {activeStep === StepsEnum.JOIN_MEETING && <JoinMeeting />}
      {activeStep === StepsEnum.CREATE_MEETING && <CreateMeeting />}
      <Footer />
    </Container>
  )
}

export default Home;