import React, { HTMLInputTypeAttribute, useState } from 'react';
import { InputGroup, Label, Input, PasswordInputEyeWrapper, EyeOpen, EyeClosed } from './style';

interface IInputProps {
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  label?: string;
  isRequired?: boolean;
  allowPasswordVisualization?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  name?: string;
  maxLength?: number;
  disabled?: boolean;
}

const TextField = (props: IInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const togglePasswordVisibilityState = () => setIsPasswordVisible(!isPasswordVisible);

  return (
    <InputGroup>
      {props.label && <Label $isRequired={!!props.isRequired}>{props.label}</Label>}
      {props.type && props.type === "password" && props.allowPasswordVisualization ? (
        <PasswordInputEyeWrapper>
          <Input 
            name={props.name}
            disabled={props.disabled} 
            type={isPasswordVisible ? 'text' : 'password'} 
            value={props.value || ""} 
            onChange={props.onChange} 
            placeholder={props.placeholder}
            maxLength={props.maxLength}
          />
          {isPasswordVisible ? <EyeOpen onClick={togglePasswordVisibilityState} /> : <EyeClosed onClick={togglePasswordVisibilityState} />}
        </PasswordInputEyeWrapper>
      ) : (
        <Input 
          name={props.name} 
          disabled={props.disabled}
          type={props.type || "text"} 
          value={props.value || ""} 
          onChange={props.onChange} 
          placeholder={props.placeholder}
          maxLength={props.maxLength}
        />
      )}
      
    </InputGroup>
  )
};

export default TextField;