import styled from 'styled-components';

export const FooterContainer = styled.div`
  position: absolute;
  bottom: 64px;
  display: flex;
  gap: 16px;
  align-items: flex-end;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 100%;
  user-select: none;
`;

export const PoweredText = styled.span`
  color: #D9D9D9;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: normal;
`;

export const AvodImageLogo = styled.img`
  width: 120px;
`;