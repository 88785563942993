import styled, { css } from "styled-components";
import { PiEyeBold, PiEyeClosedBold } from "react-icons/pi";

interface ILabelProps {
  $isRequired: boolean;
}

export const InputGroup = styled.div`
  position: relative;
  text-align: left;
`;

export const Label = styled.label<ILabelProps>`
  margin: 0;
  text-align: left;
  margin-bottom: 6px;
  font-weight: bold;
  font-family: "Noto Sans";

  ${props => props.$isRequired && `
    &:after {
      content: "*";
      color: red;
      font-weight: bold;
    }
  `}
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  border: none;
  color: #FFF;
  outline: none;
  height: 45px;
  max-height: 45px;
  box-sizing: border-box;
  padding-left: 16px;
  -moz-appearance: textfield;
  background-color: #2E2E2E;
  user-select: none;

  &::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }

  &::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  } 

  &::placeholder {
    color: #727272;
    font-size: 14px;
    font-family: "Noto Sans";
  }
`;

export const PasswordInputEyeWrapper = styled.div`
  position: relative;
`;

const EyeIconBase = css`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 8px;
  font-size: 24px;
  color: #FFF;
  cursor: pointer;
`;

export const EyeOpen = styled(PiEyeBold)`
  ${EyeIconBase}
`; 

export const EyeClosed = styled(PiEyeClosedBold)`
  ${EyeIconBase}
`; 