import styled from 'styled-components';
import { useAppContext } from '../../context';

const Svg = styled.svg<{ $marginLeft?: string }>`
  height: 42px;
  width: 178px;

  @media (max-width: 768px) {
    height: 32px;
    width: 136px;
    margin-left: ${props => props.$marginLeft || "0px"};
  }
`;

const HitsMidLogo = ({ marginLeft }: { marginLeft?: string }) => {
  const {switchToInitialPageStep } = useAppContext();
  return <>
    <Svg onClick={() => switchToInitialPageStep()} $marginLeft={marginLeft} width="178" height="42" viewBox="0 0 178 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Layer_1" clip-path="url(#clip0_1_122)">
        <path id="Vector" d="M16.327 42H13.1023V27.9502L2.29472 38.8519L0 36.5935L13.568 22.9088C14.0279 22.4447 14.7221 22.3043 15.3273 22.5512C15.9325 22.7995 16.327 23.3862 16.327 24.038V42Z" fill="#F66F40" />
        <path id="Vector_2" d="M24.7138 42H21.489V24.038C21.489 23.3862 21.8836 22.7995 22.4888 22.5512C23.094 22.3029 23.7882 22.4447 24.248 22.9088L37.8161 36.5935L35.5213 38.8519L24.7123 27.9502V42H24.7138Z" fill="#F66F40" />
        <path id="Vector_3" d="M14.7161 19.5686C14.2934 19.5686 13.8781 19.403 13.568 19.0912L0 5.40648L2.29472 3.14812L13.1038 14.0498V0H16.3285V17.962C16.3285 18.6138 15.934 19.2005 15.3288 19.4488C15.13 19.5301 14.9223 19.5686 14.7161 19.5686Z" fill="#F66F40" />
        <path id="Vector_4" d="M23.0999 19.5686C22.8937 19.5686 22.6861 19.5287 22.4873 19.4488C21.8821 19.2005 21.4875 18.6138 21.4875 17.962V0H24.7123V14.0498L35.5214 3.14812L37.8161 5.40648L24.248 19.0912C23.9395 19.403 23.5242 19.5686 23.0999 19.5686Z" fill="#F66F40" />
        <path id="Vector_5" d="M86.9456 33.8548L83.3174 33.8947C80.2528 33.8947 78.2785 32.77 78.2785 29.0336V18.1881H75.0537V14.171H78.3601V9.06894H83.4405V14.171H87.3921V18.1881H83.4405V27.628C83.4405 29.7164 83.8039 29.7578 86.9486 29.6765V33.8548H86.9456Z" fill="#F66F40" />
        <path id="Vector_6" d="M104.465 20.077C104.304 18.2295 102.288 17.5866 99.4664 17.5866C97.007 17.5467 94.9111 18.4305 95.1128 20.1568C95.4762 23.6922 109.304 20.1967 109.264 27.9502C109.224 33.0124 104.305 34.177 100.153 34.177C93.582 34.177 90.3157 31.4058 90.2356 27.3073H94.6708C94.8324 29.4356 98.0172 30.1199 99.7912 30.1598C101.324 30.2397 104.71 29.7977 104.71 28.0301C104.71 24.6159 90.4789 27.9089 90.4789 19.876C90.4789 15.8189 93.7452 13.6492 99.5895 13.6492C105.434 13.6492 108.58 16.1396 108.782 20.077H104.468H104.465Z" fill="#F66F40" />
        <path id="Vector_7" d="M112.004 33.8962V14.1724H117.042V19.1932C118.05 16.9437 120.065 14.372 122.888 14.0512C125.993 13.5694 129.097 15.4967 130.185 18.9522C131.999 15.2972 133.853 14.0512 136.878 13.9714C142.28 13.8103 145.103 17.2245 145.103 22.6073V33.8947H139.942V23.0078C139.942 20.3164 138.935 18.2679 135.91 18.2679C132.564 18.2679 131.113 20.2366 131.113 23.33V33.8947H125.953V23.0078C125.953 20.3164 124.946 18.2679 121.961 18.2679C118.615 18.2679 117.164 20.6386 117.164 23.7306V33.8932H112.004V33.8962Z" fill="#F66F40" />
        <path id="Vector_8" d="M172.961 33.8962V29.879C171.711 32.6502 168.567 34.0972 166.43 34.0972C160.464 34.0972 156.714 30.0401 156.714 24.0144C156.714 17.9886 160.464 13.9315 166.43 13.9315C168.526 13.9315 171.55 15.0962 172.839 17.9886V5.33406H178V33.8947H172.961V33.8962ZM172.881 24.0144C172.881 20.7613 170.825 18.1497 167.399 18.1497C163.972 18.1497 161.916 20.7613 161.916 24.0144C161.916 27.2674 164.054 29.879 167.399 29.879C170.743 29.879 172.881 27.2674 172.881 24.0144Z" fill="#F66F40" />
        <path id="Vector_9" d="M71.8275 8.22649H66.667V12.1239H71.8275V8.22649Z" fill="#F66F40" />
        <path id="Vector_10" d="M71.8275 14.1325H66.667V33.8962H71.8275V14.1325Z" fill="#F66F40" />
        <path id="Vector_11" d="M153.488 8.22649H148.327V12.1239H153.488V8.22649Z" fill="#F66F40" />
        <path id="Vector_12" d="M153.488 14.1325H148.327V33.8962H153.488V14.1325Z" fill="#F66F40" />
        <path id="Vector_13" d="M43.7687 33.8962V5.37544H48.9292V18.5103C50.7033 15.6179 53.4845 14.0911 55.622 13.9714C62.0315 13.5295 63.4436 18.2295 63.4436 22.6073V33.8947H58.243V23.0078C58.243 20.0356 56.9926 18.2679 54.0497 18.2679C50.6232 18.2679 48.9292 20.4376 48.9292 23.7306V33.8932H43.7687V33.8962Z" fill="#F66F40" />
      </g>
      <defs>
        <clipPath id="clip0_1_122">
          <rect width="178" height="42" fill="white" />
        </clipPath>
      </defs>
    </Svg>
    </>
}

export default HitsMidLogo;