import HitsMidLogo from '../../components/HitsMidLogo';
import { useAppContext } from '../../context';

import { 
  CreateMeetingIcon, 
  InitialPageText, 
  JoinMeetingIcon, 
  MeetingCreation, 
  MeetingCreationButton, 
  WelcomeContent, 
  WelcomeWrapper 
} from "./style";

const InitialPage = () => {
  const { switchToJoinMeetingStep, switchToCreateMeetingStep } = useAppContext();

  return (
    <WelcomeWrapper>
      <WelcomeContent>
        <HitsMidLogo />
        <InitialPageText>Conectando <br />sucesso.</InitialPageText>
      </WelcomeContent>
      <MeetingCreation>
        <MeetingCreationButton onClick={switchToCreateMeetingStep}>
          <CreateMeetingIcon />
          <span>Criar Reunião</span>
        </MeetingCreationButton>
        <MeetingCreationButton onClick={switchToJoinMeetingStep}>
          <JoinMeetingIcon />
          <span>Entrar em Reunião</span>
        </MeetingCreationButton>
      </MeetingCreation>
    </WelcomeWrapper>
  )
}

export default InitialPage;