import Loading from "../Loading";
import { StyledButton } from "./style";

interface IButtonProps {
  text?: string;
  onClick: () => void;
  disabled?: boolean;
  isLoading?: boolean;
}

const Button = ({ text, isLoading, onClick, disabled = false }: IButtonProps) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled || isLoading}>
      {isLoading && <Loading />}
      {text}
    </StyledButton>
  )
}

export default Button;