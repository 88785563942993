import { API } from "./baseService/baseService";

function getUser(token: string) {
  try {
    return API.get('/user', { headers: {'Authorization': `Bearer ${token}`}})
  } catch (error) {
    console.error('[Error] getUser:', error);
    throw error;
  }
}

function getUserMeetings(token: string, meetingType?: string) {
  try {
    return API.get(`/user/meeting/${meetingType ? '?type=' + meetingType : ''}`, { headers: { 'Authorization': `Bearer ${token}` }})
  } catch (error) {
    console.error('[Error] getUserMeetings:', error);
    throw error;
  }
}

function createMeeting(data: CreateMeetingRequest) { 
  try {
    return API.post('/user/meeting/create/', data);
  } catch (error) {
    console.error('[Error] createMeeting:', error);
    throw error;
  }
}

const UserServices = {
  getUser,
  getUserMeetings,
  createMeeting
}

export default UserServices;