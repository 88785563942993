import { AUTH_MIDDLEWARE } from "./baseService/baseService";

function auth(email: string, password: string) {
  try {
    return AUTH_MIDDLEWARE.post("/signin-broker", { email, password });
  } catch (error) {
    throw error;
  }
};

const AuthMiddlewareService = {
    auth
}

export default AuthMiddlewareService;