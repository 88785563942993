import { API } from "./baseService/baseService";

export enum MeetingUpdateActionEnum {
  END = "end",
  RECOVER = "recover"
}

function getMeeting(meetingId: number | string) {
  try {
    return API.get(`/meetings/${meetingId}`);
  } catch (error) {
    console.error('[Error] getMeeting: ', error);
    throw error;
  }
}

function updateMeetingStatus(meetingId: number, action: MeetingUpdateActionEnum, token: string) {  
  try {
    return API.put(`/meetings/status/${meetingId}`, { action }, { headers: { 'Authorization': `Bearer ${token}` }});
  } catch (error) {
    console.error('[Error] updateMeetingStatus:', error);
    throw error;
  }
}

const MeetingServices = {
  getMeeting,
  updateMeetingStatus
}

export default MeetingServices;