//import { Checkbox, FormControlLabel, ThemeProvider } from "@mui/material";
import { useAppContext } from "../../context";
import UserServices from "../../services/user";
import Button from "../Button";
import StepBox from "../StepBox";
import TextField from "../TextField";
//import { CustomStartTime, FormGroup } from "./style";
//import dayjs from 'dayjs';
import { ZoomMtg } from '@zoom/meetingsdk';
import { useRef, useState } from "react";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { theme } from "../../constants/muiTheme";
// import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import AuthMiddlewareService from "../../services/auth_middleware";
import { useSearchParams } from "react-router-dom";
import { vbList } from "../../constants/virtualBackground";
import AjxService from "../../services/ajx";
import { REDIRECT_URI } from "../../constants";
import QRCode from "qrcode.react";
import { FaClipboard } from 'react-icons/fa';
import MeetingServices from "../../services/meeting";
import { createRoot } from "react-dom/client";
import Timer from "../Timer";

const CreateMeeting = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const [searchParams, setSearchParams] = useSearchParams();
  const { isLoading, setIsLoading, joinMeeting, switchToInitialPageStep } = useAppContext();

  const [meetingConfig, setMeetingConfig] = useState<CreatedMeeting>({ roomCode: "", password: "" });
  const [createMeetingParams, setCreateMeetingParams] = useState<CreateMeetingParams>({
    password: "",
    email: "",
    //hasCustomStartTime: false,
    username: ""
  });

  const updateQrCodeUrl = (roomCode: string, password: string) => {
    setQrCodeUrl(`${REDIRECT_URI}?room=${roomCode}&password=${password}`);
  }

  // Brokers pinados. Utilizado caso o host seja um lider
  const pinnedBrokers = useRef<number[]>([]);
  // Id do setTimeout usado para realizar a requisição dos lideres na chamada
  const onUserJoinTimeoutId = useRef<number | null>(null);
  // Lista de co-hosts que estão na sessão
  const coHostLeaders = useRef<number[]>([]);

  //const handleStartTime = (value: any) => setCreateMeetingParams({ ...createMeetingParams, start_time: value });
  const handleCreateMeetingParams = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = (event.target.type === "checkbox" ? event.target.checked : event.target.value);

    setCreateMeetingParams(currState => ({ ...currState, [name]: value }));
  };

  const generateRandomPassword = (length: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }
    return password;
  }

  const createMeeting = async () => {
    // if (createMeetingParams.hasCustomStartTime && dayjs(createMeetingParams.start_time).isBefore(dayjs(new Date()))) {
    //   toast.error("Data ou horário inválido.");
    //   return;
    // }

    try {
      const responseAuthMiddlware = await AuthMiddlewareService.auth(createMeetingParams.email, createMeetingParams.password??'');
      if (responseAuthMiddlware.status !== 200) { 
        toast.error("Não foi possível realizar login");
        return;
      }
    } catch (err) {
      toast.error("Não foi possível realizar login");
      return;
    }

    try {
      setIsLoading(true);
      createMeetingParams.password = generateRandomPassword(10)
      const meeting = {
        password: createMeetingParams.password,
        //...((createMeetingParams.hasCustomStartTime && createMeetingParams.start_time) && { start_time: createMeetingParams.start_time.format(), timezone: "America/Sao_Paulo" }),
      };

      const response = await UserServices.createMeeting(meeting);
      if (response.status === 200) {
        const data = response.data;
        updateQrCodeUrl(data.id, data.password);
        setMeetingConfig({ password: data.password, roomCode: data.id });
      } else {
        toast.error("Ocorreu um erro ao criar a reunião");
      }
    } catch (err) {
      console.error('[createMeeting]: ', err);
      toast.error("Ocorreu um erro ao criar a reunião");
    } finally {
      setIsLoading(false);
    }
  };

  const handleJoinSuccess = async (success: ZoomSDKResponse) => {
    console.log('== JOIN SUCCESS: ', success);

    // Adicionar roomCode na URL
    if (!searchParams.has("room") && meetingConfig.roomCode) {
      searchParams.append("room", meetingConfig.roomCode);
      searchParams.append("password", meetingConfig.password);
      setSearchParams(searchParams);
    }

    const response = await MeetingServices.getMeeting(meetingConfig.roomCode);
    if (response.status === 200) {
      const data: Meeting = response.data;

      const meetingElement = document.getElementById('zmmtg-root');
      if (meetingElement) {
        const element = document.createElement('div');
        element.className = "hitsmid-meeting-timer";
        element.style.display = "none";
        meetingElement.appendChild(element);
        const root = createRoot(element)
        root.render(<Timer initialDate={new Date(data.created_at)} />)
      }
    }
    // Obtem o usuario (Host) = ETAPA 1
    // ZoomMtg.getCurrentUser({
    //   success: getCurrentUserSuccess,
    //   error: getCurrentUserError
    // });

    // Inicializa listener de novos usuarios na chamada = ETAPA 2
    // ZoomMtg.inMeetingServiceListener("onUserJoin", handleOnUserJoin);

    // Verifica por participantes saindo para remover da lista de co-host
    // ZoomMtg.inMeetingServiceListener("onUserLeave", handleOnUserLeave);
  };

  // == updateVirtualBackgroundList
  const updateVirtualBackgroundListSuccess = (event: any) => {
    console.log('Successfully updated the virtual background list. ', event);
  };

  const updateVirtualBackgroundListError = (event: any) => {
    console.error('An error occurred while updating the virtual background list. ', event);
  };

  // == getCurrentUser
  const getCurrentUserSuccess = async (user: GetCurrentUser) => {
    console.log('getCurrentUserSuccess');

    try {
      const data = {
        attendee_id: user.result.currentUser.userId + "",
        session_id: meetingConfig.roomCode + "",
        email_attendee: createMeetingParams.email
      }
      console.log('data: ', data);

      await AjxService.addAttendanceHistory(data);
    } catch (err) {
      console.error("[getCurrentUserSuccess]: ", err);
    }
  };

  const getCurrentUserError = (error: any) => {
    console.error('[getCurrentUser]: ', error);
  };

  // == onUserJoin
  const handleOnUserJoin = () => {
    // Irá aguardar 5s para que mais pessoas entrem
    if (!onUserJoinTimeoutId.current) {
      onUserJoinTimeoutId.current = window.setTimeout(makeLeadersInMeetingCoHost, 5000);
    }
  };

  const makeLeadersInMeetingCoHost = async () => {
    console.log('makeLeadersInMeetingCoHost');
    onUserJoinTimeoutId.current = null;
    const timer = (ms: number) => new Promise(res => setTimeout(res, ms))

    try {
      const response = await AjxService.leadersAttendanceBySessionId(meetingConfig.roomCode + "", "Y");
      console.log('response leadersAttendanceBySessionId: ', response);
      if (response.status === 200) {
        const data = response.data;
        console.log('data: ', data);
        const leaders = data.data;
        console.log('leaders: ', leaders);
        for (const leader of leaders) {
          // Verifica se um dado lider já foi tornado co-host
          if (!coHostLeaders.current.includes(leader.attendee_id)) {
            // Verifica se o lider é o proprio host. Se for, não é 
            // necessario trocar sua atribuição para realizar o multi-pin
            if (leader.email !== createMeetingParams.email) {
              console.log(`tornando email ${leader.email} com attendee_id ${leader.attendee_id} co-host`);

              const userId = Number.parseInt(leader.attendee_id, 10); 
              ZoomMtg.makeCoHost({
                userId: userId,
                success: (e: any) => {
                  console.log('makeCoHost success: ', e);
                  coHostLeaders.current = [...coHostLeaders.current, userId];
                },
                error: (e: any) => console.error("makeCoHost error: ", e)
              });
              timer(2000);
            } else {
              // O maximo de usuarios que podem ser pinados é 9
              if (pinnedBrokers.current.length < 9) {
                pinBrokers();
              }
            }
          }
        }
      }
    } catch (err) {
      console.error('[makeLeadersInMeetingCoHost]: ', err);
    }
  };

  const pinBrokers = async () => {
    console.log('pinBrokers');

    try {
      // Vai obter a lista de liderados (geral)
      const brokersResponse = await AjxService.brokersByLeaderEmail(createMeetingParams.email);
      if (brokersResponse.status === 200) {
        const data = brokersResponse.data;
        const brokers = data?.data?.result?.brokers;
        console.log('brokers: ', brokers);
        if (brokers && Array.isArray(brokers) && brokers.length) {
          // Obtem a lista de todas as pessoas que estão na reunião (para obter o userId delas)
          const meetingParticipantsResponse = await AjxService.leadersAttendanceBySessionId(meetingConfig.roomCode + "", "N");
          if (meetingParticipantsResponse.status === 200) {
            const data = meetingParticipantsResponse.data;
            const participants = data.data;
            if (participants && Array.isArray(participants) && participants.length) {
              // É realizado uma filtragem de todos os participantes com: 
              //  - somente os brokers do lider
              //  - brokers que não foram ainda pinados
              const leaderBrokersInCall = participants.filter(participant => {
                return brokers.find(broker => broker.email === participant.email && createMeetingParams.email !== broker.email && !pinnedBrokers.current.includes(participant.attendee_id))
              })
              console.log('leaderBrokersInCall: ', leaderBrokersInCall);
              for (const broker of leaderBrokersInCall) {
                if (pinnedBrokers.current.length >= 9) break;

                const userId = Number.parseInt(broker.attendee_id, 10) 
                ZoomMtg.operatePin({
                  operate: "add",
                  userId: userId,
                  success: (e: any) => {
                    console.log('operatePin success: ', e);
                    pinnedBrokers.current = [...pinnedBrokers.current, userId];
                  },
                  error: (e: any) => console.error('operatePin error: ', e)
                })
              }
            }
          }
        }
      }
    } catch (err) { 
      console.error("[pinBrokers]: ", err);
    }
  };

  // == onUserLeave
  const handleOnUserLeave = (user: User) => {
    console.log('handleOnUserLeave: ', user);
    
    pinnedBrokers.current = pinnedBrokers.current.filter(i => i !== user.userId);
    coHostLeaders.current = coHostLeaders.current.filter(i => i !== user.userId);
  }

  const copyToClipboard = () => {
    const message = `Vamos de Hits! Este é o link da nossa chamada.\nLink: ${qrCodeUrl}`;
    navigator.clipboard.writeText(message).then(() => {
      toast.success("Link copiado com sucesso!");
    }, (err) => {
      toast.error("Ocorreu um erro ao copiar");
    });
  };

  // == joinMeeting
  const joinMeetingAsHost = () => {
    joinMeeting(
      createMeetingParams.username,
      meetingConfig.password,
      meetingConfig.roomCode,
      1,
      handleJoinSuccess
    )
  };

  if (meetingConfig.roomCode && meetingConfig.password) {
    return (
      <StepBox stepTitle="Entrar em reunião">
        <div style={{textAlign: 'center'}}>
          <p>Código da Reunião: <strong>{meetingConfig.roomCode}</strong></p>
          <p>Senha para acesso: <strong>{meetingConfig.password}</strong></p>
          <p>Copiar conteúdo: <FaClipboard onClick={copyToClipboard} style={{ cursor: 'pointer', alignItems: "center" }} title="Copiar link" /></p><br></br>
          <div style={{ backgroundColor: '#fff', padding: '10px', display: 'inline-block', borderRadius: 10, width: 170, height: 170, alignContent: 'center' }}>
            <QRCode value={qrCodeUrl} size={150}/>
          </div><br></br><br></br><br></br>
          <Button text="Entrar" onClick={joinMeetingAsHost} disabled={isLoading} />
        </div>
      </StepBox>
    )
  }

  return (
    <StepBox stepTitle="Criar uma reunião" onBackButtonClicked={switchToInitialPageStep}>
      {/* <FormGroup className='schedule-meeting-date'>
        <ThemeProvider theme={theme}>
          <FormControlLabel
            label="Definir horário de inicio"
            control={(
              <Checkbox
                style={{ color: 'white' }}
                name="hasCustomStartTime"
                onChange={handleCreateMeetingParams}
                checked={createMeetingParams.hasCustomStartTime}
              />
            )}
          />
        </ThemeProvider>
        <CustomStartTime $expanded={createMeetingParams.hasCustomStartTime}>
          {createMeetingParams.hasCustomStartTime && (
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  value={createMeetingParams.start_time || null}
                  onChange={handleStartTime}
                  format="DD/MM/YYYY HH:mm"
                  ampm={false}
                  disablePast
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </LocalizationProvider>
            </ThemeProvider>
          )}
        </CustomStartTime>
      </FormGroup> */}
      <TextField
        isRequired
        type="email"
        name="email"
        label="Email"
        placeholder="Insira seu e-mail"
        value={createMeetingParams.email}
        onChange={handleCreateMeetingParams}
      />
      <TextField
        isRequired
        type="password"
        label="Senha"
        name="password"
        disabled={isLoading}
        allowPasswordVisualization
        placeholder="Insira sua senha"
        value={createMeetingParams.password}
        onChange={handleCreateMeetingParams}
        maxLength={50}
      />
      <TextField
        isRequired
        label="Nome de Usuário"
        type="text"
        name="username"
        disabled={isLoading}
        placeholder='Nome a ser usado durante a reunião'
        value={createMeetingParams.username}
        onChange={handleCreateMeetingParams}
      />
      <Button
        onClick={createMeeting}
        text="Criar Reunião"
        isLoading={isLoading}
        disabled={!createMeetingParams.email || !createMeetingParams.username|| !createMeetingParams.password}
      />
    </StepBox>
  );
};

export default CreateMeeting;